'use strict';

import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
import 'tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min';

import BackboneExtend from './../../../util/backbone_extend';

const MobileCheckStoreView = BackboneExtend.View.extend({
  el: 'body',
  nanobar: new Nanobar({
    id: 'body',
  }),
  initialize: function (options) {
    this.options = options;

    this.initialize_events();
  },
  events: {
    'change #mobilecheck_store_selector': 'store_selector',
  },
  store_selector: function (evt) {
    var pid = evt.target.value;

    if (pid.length == 8) {
      window.location.href = '/' + 'store' + '/' + pid;
    } else {
      alert('store_selector: pid inválido');
    }
  }
});

export default MobileCheckStoreView;