'use strict';

import MobileCheckContainerView from './views/container_view';

import { MobileCheckMainRouter } from './routers/routers';

class MobileCheck {
  constructor() {
    new MobileCheckMainRouter();
    new MobileCheckContainerView();

    Backbone.history.start({
      pushState: true
    });

    console.log(`[MobileCheck] v${MOBILECHECK_VERSION}`);
  }
}

export default new MobileCheck();

export function version() {
  return `v${MOBILECHECK_VERSION}`;
}