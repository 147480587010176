'use strict';

import BackboneExtend from './../../util/backbone_extend';

import MobileCheckOverviewIntentionView from './../views/overview/overview_intention_view';
import MobileCheckOverviewPlanogramView from './../views/overview/overview_planogram_view';
import MobileCheckOverviewPreventionView from './../views/overview/overview_prevention_view';
import MobileCheckOverviewView from './../views/overview/overview_view';

import MobileCheckStoreView from './../views/store/store_view';
import MobileCheckViewerView from './../views/store/store_viewer_view';

var MobileCheckMainRouter = Backbone.Router.extend({
  initialize: function (opts) {
    BackboneExtend.initializeEventBus(this);
},
  bind: function (evt, cb, ctx) {
    this.evtbus.bind(evt, cb, ctx);
  },
  trigger: function (evt, opts) {
    this.evtbus.trigger(evt, opts);
  },
  routes: {
    'overview/intention': function () {
      new MobileCheckOverviewIntentionView();
    },
    'overview/planogram': function () {
      new MobileCheckOverviewPlanogramView();
    },
    'overview/prevention': function () {
      new MobileCheckOverviewPreventionView();
    },
    'overview': function () {
      new MobileCheckOverviewView();
    },
    'store': function () {
      new MobileCheckStoreView();
    },
    'store/:pid': function (pid) {
      new MobileCheckViewerView({
        'pid': pid,
      });
    },
    'store/:pid/:date_from/:date_to': function (pid, date_from, date_to) {
      new MobileCheckViewerView({
        'pid': pid,
        'date_from': date_from,
        'date_to': date_to,
      });
    },
    // 'store/:pid/:date_filter': function (pid, date_filter) {
    //    new MobileCheckViewerView({
    //       'pid': pid,
    //       'date_filter': date_filter
    //    });

    //    console.log(pid, date_filter);
    // },
    '': function () {
      new MobileCheckOverviewView();
    },
  },
});

export { MobileCheckMainRouter };