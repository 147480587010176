'use strict';

const MobileCheckDateRangeDataModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/date_range',
  defaults: {
    'date_min': '',
    'date_max': '',
  },
});

const MobileCheckOverviewDataModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/overview',
  defaults: {
    'intencao_de_compra_por_fabricante': [],
    'presenca_por_fabricante': [],
    'tentativas_de_bloqueio_por_fabricante': [],
  },
});

const MobileCheckOverviewIntentionDataModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/overview_intention',
  defaults: {
    'intencao_de_compra_por_loja': [],
  },
});

const MobileCheckOverviewPlanogramDataModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/overview_planogram',
  defaults: {
  },
});

const MobileCheckOverviewPreventionDataModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/overview_prevention',
  defaults: {
  },
});

const MobileCheckStoreDataModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/store',
  defaults: {
    'intencao_de_compra_por_fabricante': {},
    'presenca_por_fabricante': {},
    'tentativas_de_bloqueio_por_fabricante': {},
    'intencao_de_compra_por_dispositivo': [],
  },
});

const MobileCheckStoreSelectorDataModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/store_selector',
  defaults: {
    'lojas': [],
  },
});

const MobileCheckStoreInstalacoesDoAppDetalhadoModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/store_instalacoes_do_app_detalhado',
  defaults: {
    'instalacoes_do_app_detalhado': [],
  },
});

const MobileCheckStoreMostruariosVendidosDetalhadoModel = Backbone.Model.extend({
  initialize: function (options) {
    this.options = options;
  },
  url: '/api/v1/store_mostruarios_vendidos_detalhado',
  defaults: {
    'mostruarios_vendidos_detalhado': [],
  },
});

export {
  MobileCheckDateRangeDataModel,
  MobileCheckOverviewDataModel,
  MobileCheckOverviewIntentionDataModel,
  MobileCheckOverviewPlanogramDataModel,
  MobileCheckOverviewPreventionDataModel,
  MobileCheckStoreDataModel,
  MobileCheckStoreSelectorDataModel,
  MobileCheckStoreInstalacoesDoAppDetalhadoModel,
  MobileCheckStoreMostruariosVendidosDetalhadoModel,
}