'use strict';

var BackboneExtend = {};

BackboneExtend.initializeEventBus = function(instance) {
  if (typeof window.MobileCheckEventBus === 'undefined') {
    window.MobileCheckEventBus = new _.extend({}, Backbone.Events);
  }

  if (typeof instance !== 'undefined') {
    instance.evtbus = window.MobileCheckEventBus;
  }
}

BackboneExtend.View = Backbone.View.extend({
  initialize_events: function() {
    BackboneExtend.initializeEventBus(this);
  },
  bind: function(event, callback, context) {
    this.evtbus.bind(event, callback, context);
  },
  trigger: function(event, options, data) {
    this.evtbus.trigger(event, options, data);
  }
});

export default BackboneExtend;