'use strict';

import BackboneExtend from './../../../util/backbone_extend';
import MobileCheckTemplate from './../../../../../template/tpl/mobilecheck.tpl';

import { MobileCheckOverviewPreventionDataModel } from './../../models/models';

import { chart_aderencia_a_guarda_diaria_por_loja, } from './../../charts/charts';

const MobileCheckOverviewPreventionView = BackboneExtend.View.extend({
  el: 'body',
  nanobar: new Nanobar({
    id: 'body',
  }),
  initialize: function (options) {
    this.options = options;

    this.initialize_events();

    this.patch();
  },
  patch: function () {
    var _this = this;

    new MobileCheckOverviewPreventionDataModel().fetch({
      cache: false,
      contentType: 'application/json',
      type: 'get',
      success: function(response) {
        var data = response.toJSON();

        chart_aderencia_a_guarda_diaria_por_loja('chart_aderencia_a_guarda_diaria_por_loja', data['aderencia_a_guarda_diaria_por_loja']);
      },
      error: function (response) {
        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_data_is_unavailable_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html())({});

          var aderencia_a_guarda_diaria_por_loja = _this.$el.find('#chart_aderencia_a_guarda_diaria_por_loja');

          if (aderencia_a_guarda_diaria_por_loja.length > 0) {
            aderencia_a_guarda_diaria_por_loja.html(t);
          }
        }
      }
    });
  }
});

export default MobileCheckOverviewPreventionView;