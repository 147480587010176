'use strict';

import BackboneExtend from './../../../util/backbone_extend';
import MobileCheckTemplate from './../../../../../template/tpl/mobilecheck.tpl';

import { MobileCheckOverviewPlanogramDataModel } from './../../models/models';

import { chart_aderencia_ao_planograma_por_loja, } from './../../charts/charts';

const MobileCheckOverviewPlanogramView = BackboneExtend.View.extend({
  el: 'body',
  nanobar: new Nanobar({
    id: 'body',
  }),
  initialize: function (options) {
    this.options = options;

    this.initialize_events();

    this.patch();
  },
  patch: function () {
    var _this = this;

    new MobileCheckOverviewPlanogramDataModel().fetch({
      cache: false,
      contentType: 'application/json',
      type: 'get',
      success: function(response) {
        var data = response.toJSON();

        chart_aderencia_ao_planograma_por_loja('chart_aderencia_ao_planograma_por_loja', data['aderencia_ao_planograma_por_loja']);
      },
      error: function (response) {
        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_data_is_unavailable_tpl');

        if (tpl.length > 0) {
          var template = _.template(tpl.html())({});

          if (store_data_is_unavailable.length > 0) {
            var aderencia_ao_planograma_por_loja = _this.$el.find('#chart_aderencia_ao_planograma_por_loja');

            if (aderencia_ao_planograma_por_loja.length > 0) {
              aderencia_ao_planograma_por_loja.html(template);
            }
          }
        }
      }
    });
  }
});

export default MobileCheckOverviewPlanogramView;