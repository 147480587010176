'use strict';

import BackboneExtend from './../../../util/backbone_extend';
import MobileCheckTemplate from './../../../../../template/tpl/mobilecheck.tpl';

import { MobileCheckOverviewDataModel, } from './../../models/models';

import { chart_intencao_de_compra_por_fabricante,
         chart_presenca_por_fabricante,
         chart_tentativas_de_bloqueio_por_fabricante, } from './../../charts/charts';

const MobileCheckOverviewView = BackboneExtend.View.extend({
  el: 'body',
  nanobar: new Nanobar({id: 'body'}),
  location_map: null,
  location_map_token: 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
  initialize: function (options) {
    this.options = options;

    this.initialize_events();

    this.patch();
  },
  patch: function () {
    var _this = this;

    new MobileCheckOverviewDataModel().fetch({
      cache: false,
      contentType: 'application/json',
      type: 'get',
      success: function(response) {
        var dataJSON = response.toJSON();

        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_data_value_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html());

          var lojas_ativas = _this.$el.find('.lojas_ativas');

          if (lojas_ativas.length > 0) {
            lojas_ativas.html(t({
              'style': 'text-primary',
              'value': dataJSON['lojas_ativas']
            }));
          }

          var dispositivos_monitorados = _this.$el.find('.dispositivos_monitorados');

          if (dispositivos_monitorados.length > 0) {
            dispositivos_monitorados.html(t({
              'style': 'text-primary',
              'value': dataJSON['dispositivos_monitorados']
            }));
          }

          var dispositivos_por_loja = _this.$el.find('.dispositivos_por_loja');

          if (dispositivos_por_loja.length > 0) {
            dispositivos_por_loja.html(t({
              'style': 'text-primary',
              'value': dataJSON['dispositivos_por_loja']
            }));
          }

          var mostruarios_vendidos = _this.$el.find('.mostruarios_vendidos');

          if (mostruarios_vendidos.length > 0) {
            mostruarios_vendidos.html(t({
              'style': 'text-tertiary',
              'value': dataJSON['mostruarios_vendidos']
            }));
          }

          var instalacoes_do_app = _this.$el.find('.instalacoes_do_app');

          if (instalacoes_do_app.length > 0) {
            instalacoes_do_app.html(t({
              'style': 'text-tertiary',
              'value': dataJSON['instalacoes_do_app']
            }));
          }

          var nivel_medio_de_bateria = _this.$el.find('.nivel_medio_de_bateria');

          if (nivel_medio_de_bateria.length > 0) {
            if (dataJSON['nivel_medio_de_bateria'] != null) {
              nivel_medio_de_bateria.html(t({
                'style': 'text-tertiary',
                'value': dataJSON['nivel_medio_de_bateria'].toFixed(2) + '%'
              }));
            } else {
              nivel_medio_de_bateria.html(t({
                'style': 'text-tertiary',
                'value': dataJSON['nivel_medio_de_bateria']
              }));
            }
          }
        }

        // _this.render_device_locations(dataJSON['localizacao_dos_dispositivos']);

        chart_intencao_de_compra_por_fabricante('chart_intencao_de_compra_por_fabricante', dataJSON['intencao_de_compra_por_fabricante']);
        chart_presenca_por_fabricante('chart_presenca_por_fabricante', dataJSON['presenca_por_fabricante']);
        chart_tentativas_de_bloqueio_por_fabricante('chart_tentativas_de_bloqueio_por_fabricante', dataJSON['tentativas_de_bloqueio_por_fabricante']);
      },
      error: function (response) {
        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_data_value_unavailable_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html())({});

          var lojas_ativas = _this.$el.find('.lojas_ativas');

          if (lojas_ativas.length > 0) {
            lojas_ativas.html(t);
          }

          var dispositivos_monitorados = _this.$el.find('.dispositivos_monitorados');

          if (dispositivos_monitorados.length > 0) {
            dispositivos_monitorados.html(t);
          }

          var dispositivos_por_loja = _this.$el.find('.dispositivos_por_loja');

          if (dispositivos_por_loja.length > 0) {
            dispositivos_por_loja.html(t);
          }

          var mostruarios_vendidos = _this.$el.find('.mostruarios_vendidos');

          if (mostruarios_vendidos.length > 0) {
            mostruarios_vendidos.html(t);
          }

          var instalacoes_do_app = _this.$el.find('.instalacoes_do_app');

          if (instalacoes_do_app.length > 0) {
            instalacoes_do_app.html(t);
          }

          var nivel_medio_de_bateria = _this.$el.find('.nivel_medio_de_bateria');

          if (nivel_medio_de_bateria.length > 0) {
            nivel_medio_de_bateria.html(t);
          }

          var intencao_de_compra_por_fabricante = _this.$el.find('#chart_intencao_de_compra_por_fabricante');

          if (intencao_de_compra_por_fabricante.length > 0) {
            intencao_de_compra_por_fabricante.html(t);
          }

          var presenca_por_fabricante = _this.$el.find('#chart_presenca_por_fabricante');

          if (presenca_por_fabricante.length > 0) {
            presenca_por_fabricante.html(t);
          }

          var tentativas_de_bloqueio_por_fabricante = _this.$el.find('#chart_tentativas_de_bloqueio_por_fabricante');

          if (tentativas_de_bloqueio_por_fabricante.length > 0) {
            tentativas_de_bloqueio_por_fabricante.html(t);
          }
        }
      }
    });
  },
  render_device_locations: function(device_locations) {
    var center_at = [0, 0];
    var sensors_layer = L.layerGroup();

    _.each(device_locations, function(device_location, idx) {
      var l = [device_location['la'], device_location['lo']];

      if (idx == 0) {
        center_at = l;
      }

      var popup = "<b>latitude</b>: " + device_location['la'] + "°" + "<br>" + "<b>longitude</b>: " + device_location['lo'] + "°" + "<br>" + "<b>accuracy</b>: " + device_location['ac'] + "m";

      L.marker(l).bindPopup(popup).addTo(sensors_layer);
    })

    var tiles_url = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + this.location_map_token;
    var tiles_layer = L.tileLayer(tiles_url, {id: 'mapbox.streets'});

    this.location_map_cleanup();

    this.location_map = L.map('canvasOnMap', {
      zoom: 13,
      center: center_at,
      layers: [tiles_layer, sensors_layer]
    });
  },
  location_map_cleanup: function() {
    if (this.location_map != null) {
      this.location_map.off();
      this.location_map.remove();
      this.location_map = null;
    }
  }
});

export default MobileCheckOverviewView;