'use strict';

import BackboneExtend from './../../../util/backbone_extend';
import MobileCheckTemplate from './../../../../../template/tpl/mobilecheck.tpl';

import { MobileCheckOverviewIntentionDataModel } from './../../models/models';

import { chart_intencao_de_compra_por_loja,
         chart_intencao_de_compra_por_modelo_de_dispositivo, } from './../../charts/charts';

const MobileCheckOverviewIntentionView = BackboneExtend.View.extend({
  el: 'body',
  nanobar: new Nanobar({
    id: 'body',
  }),
  initialize: function (options) {
    this.options = options;

    this.initialize_events();

    this.patch();
  },
  patch: function () {
    var _this = this;

    new MobileCheckOverviewIntentionDataModel().fetch({
      cache: false,
      contentType: 'application/json',
      type: 'get',
      success: function(response) {
        var data = response.toJSON();

        chart_intencao_de_compra_por_loja('chart_intencao_de_compra_por_loja', data['intencao_de_compra_por_loja']);
        chart_intencao_de_compra_por_modelo_de_dispositivo('chart_intencao_de_compra_por_modelo_de_dispositivo', data['intencao_de_compra_por_modelo_de_dispositivo']);
      },
      error: function (response) {
        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_data_value_unavailable_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html())({});

          var intencao_de_compra_por_loja = _this.$el.find('#chart_intencao_de_compra_por_loja');

          if (intencao_de_compra_por_loja.length > 0) {
            intencao_de_compra_por_loja.html(t);
          }

          var intencao_de_compra_por_modelo_de_dispositivo = _this.$el.find('#chart_intencao_de_compra_por_modelo_de_dispositivo');

          if (intencao_de_compra_por_modelo_de_dispositivo.length > 0) {
            intencao_de_compra_por_modelo_de_dispositivo.html(t);
          }
        }
      }
    });
  }
});

export default MobileCheckOverviewIntentionView;