'use strict';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../css/mobilecheck.css';
import './../../../css/main.css';

import BackboneExtend from '../../util/backbone_extend';
import Nanobar from 'nanobar/nanobar.min';

import Leaflet from 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';

import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faBatteryHalf } from "@fortawesome/free-solid-svg-icons/faBatteryHalf";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons/faCalendarTimes";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faChartPie } from "@fortawesome/free-solid-svg-icons/faChartPie";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons/faChevronCircleRight";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons/faHeartbeat";
import { faMedkit } from "@fortawesome/free-solid-svg-icons/faMedkit";
import { faMobile } from "@fortawesome/free-solid-svg-icons/faMobile";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons/faMoneyBillAlt";
import { faPager } from "@fortawesome/free-solid-svg-icons/faPager";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faPlug } from "@fortawesome/free-solid-svg-icons/faPlug";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons/faPowerOff";
import { faQrcode } from "@fortawesome/free-solid-svg-icons/faQrcode";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faStore } from "@fortawesome/free-solid-svg-icons/faStore";
import { faTable } from "@fortawesome/free-solid-svg-icons/faTable";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons/faUnlockAlt";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";

import { library, dom } from '@fortawesome/fontawesome-svg-core';

library.add(faAngleUp);
library.add(faBatteryHalf);
library.add(faBell);
library.add(faCalendar);
library.add(faCalendarAlt);
library.add(faCalendarTimes);
library.add(faChartLine);
library.add(faChartPie);
library.add(faCheck);
library.add(faQuestion);
library.add(faCheckCircle);
library.add(faCheckDouble);
library.add(faChevronCircleRight);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faClipboard);
library.add(faClock);
library.add(faExclamation);
library.add(faExclamationTriangle);
library.add(faEye);
library.add(faEyeSlash);
library.add(faFile);
library.add(faGlobe);
library.add(faHeartbeat);
library.add(faMedkit);
library.add(faMobile);
library.add(faMobileAlt);
library.add(faMoneyBillAlt);
library.add(faPager);
library.add(faPlay);
library.add(faPlus);
library.add(faPowerOff);
library.add(faQrcode);
library.add(faQuestionCircle);
library.add(faShoppingCart);
library.add(faSignOutAlt);
library.add(faStore);
library.add(faTable);
library.add(faTimes);
library.add(faTrash);
library.add(faTrashAlt);
library.add(faUnlockAlt);
library.add(faUser);
library.add(faPlug);
library.add(faUsers);

dom.watch();

import 'bootstrap/dist/css/bootstrap.min.css';

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/tooltip';

const MobileCheckContainerView = BackboneExtend.View.extend({
  el: 'body',
  nanobar: new Nanobar({
    id: 'body',
  }),
  initialize: function (options) {
    this.options = options;

    _.bindAll(this, 'nanobar_update',
               'preloader_update',);

    this.initialize_events();

    this.bind('container:nanobar', this.nanobar_update);
    this.bind('container:preloader', this.preloader_update);

    this.patch();
  },
  patch: function () {
    var _this = this;

    $('[data-toggle="tooltip"]').tooltip();

    this.preloader_update(500);
  },
  nanobar_update: function (progress) {
    this.nanobar.go(progress);
  },
  preloader_update: function (delay) {
    var preloader = $('.preloader');

    if (preloader.length) {
      preloader.delay(delay).fadeOut('fast');
    }
  }
});

export default MobileCheckContainerView;
