'use strict';

import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
import 'tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min';

import BackboneExtend from './../../../util/backbone_extend';
import MobileCheckTemplate from './../../../../../template/tpl/mobilecheck.tpl';

import { MobileCheckDateRangeDataModel,
         MobileCheckStoreSelectorDataModel,
         MobileCheckStoreMostruariosVendidosDetalhadoModel,
         MobileCheckStoreDataModel,
         MobileCheckStoreInstalacoesDoAppDetalhadoModel, } from './../../models/models';

import { chart_intencao_de_compra_por_fabricante,
         chart_presenca_por_fabricante,
         chart_tentativas_de_bloqueio_por_fabricante,
         chart_tempo_de_prateleira_por_dispositivo,
         chart_intencao_de_compra_por_dispositivo,
         chart_nivel_de_sinal_do_wifi_por_dispositivo, } from './../../charts/charts';

const MobileCheckStoreViewerView = BackboneExtend.View.extend({
  el: 'body',
  nanobar: new Nanobar({
    id: 'body',
  }),
  initialize: function (options) {
    this.options = options;

    this.initialize_events();

    this.patch();
  },
  events: {
    'change #mobilecheck_store_selector': 'store_selector',
    //
    'click #mobilecheck_store_datetimepicker_filter_today': 'store_datetimepicker_filter_today',
    'click #mobilecheck_store_datetimepicker_filter_yesterday': 'store_datetimepicker_filter_yesterday',
    'click #mobilecheck_store_datetimepicker_filter_week': 'store_datetimepicker_filter_week',
    'click #mobilecheck_store_datetimepicker_filter_month': 'store_datetimepicker_filter_month',
    'click #mobilecheck_store_datetimepicker_filter_quarter': 'store_datetimepicker_filter_quarter',
    'click #mobilecheck_store_datetimepicker_filter_year': 'store_datetimepicker_filter_year',
    //
    'click #mobilecheck_store_instalacoes_do_app_detalhado': 'store_instalacoes_do_app_detalhado',
    'click #mobilecheck_store_mostruarios_vendidos_detalhado': 'store_mostruarios_vendidos_detalhado',
  },
  patch: function () {
    var _this = this;

    new MobileCheckDateRangeDataModel().fetch({
      cache: false,
      contentType: 'application/json',
      type: 'post',
      data: JSON.stringify({
        'pid': _this.options.pid
      }),
      success: function (response) {
        var data = response.toJSON();

        if (data["date_min"] == "" ||
            data["date_max"] == "")
        {
          var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_unavailable_data_tpl');

          if (tpl.length > 0) {
            var t = _.template(tpl.html());

            var store_unavailable_data_modal = $(t({
              'message': 'a loja selecionada não possui dados para exibição'
            })).filter('#mobilecheck_store_unavailable_data_modal');

            if (store_unavailable_data_modal.length > 0) {
              store_unavailable_data_modal.modal({
                backdrop: true,
                focus: true,
                keyboard: false,
                show: true
              });
            }
          }

          _this.store_data_is_unavailable();

          return;
        }

        var date_min = moment(data['date_min']);
        var date_max = moment(data['date_max']);

        var date_min_current = date_min;
        var date_max_current = date_max;

        if ("date_from" in _this.options) {
          var date_from = moment(_this.options.date_from, "DD_MM_YYYY");

          if (date_from >= date_min &&
             date_from <= date_max)
          {
            date_min_current = date_from;
          } else {
            var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_unavailable_data_tpl');

            if (tpl.length > 0) {
              var t = _.template(tpl.html());

              var store_unavailable_data_modal = $(t({
                'message': 'a data de início informada é menor que a data mínima disponível de dados'
              })).filter('#mobilecheck_store_unavailable_data_modal');

              if (store_unavailable_data_modal.length > 0) {
                store_unavailable_data_modal.modal({
                  backdrop: true,
                  focus: true,
                  keyboard: false,
                  show: true
                });
              }
            }

            _this.store_data_is_unavailable();

            return;
          }
        }

        if ("date_to" in _this.options) {
          var date_to = moment(_this.options.date_to, "DD_MM_YYYY");

          if (date_to <= date_max &&
             date_to >= date_min)
          {
            date_max_current = date_to;
          } else {
            var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_unavailable_data_tpl');

            if (tpl.length > 0) {
              var t = _.template(tpl.html());

              var store_unavailable_data_modal = $(t({
                'message': 'a data de término informada é maior que a data máxima disponível de dados'
              })).filter('#mobilecheck_store_unavailable_data_modal');

              if (store_unavailable_data_modal.length > 0) {
                store_unavailable_data_modal.modal({
                  backdrop: true,
                  focus: true,
                  keyboard: false,
                  show: true
                });
              }
            }

            return;
          }
        }

        if (date_min_current > date_max_current) {
          var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_unavailable_data_tpl');

          if (tpl.length > 0) {
            var t = _.template(tpl.html());

            var store_unavailable_data_modal = $(t({
              'message': 'a data de início informada não pode ser maior que a data término informada'
            })).filter('#mobilecheck_store_unavailable_data_modal');

            if (store_unavailable_data_modal.length > 0) {
              store_unavailable_data_modal.modal({
                backdrop: true,
                focus: true,
                keyboard: false,
                show: true
              });
            }
          }

          return;
        }

        _this.datepicker(date_min, date_min_current, date_max, date_max_current);

        new MobileCheckStoreDataModel().fetch({
          cache: false,
          contentType: 'application/json',
          type: 'post',
          data: JSON.stringify({
            'pid': _this.options.pid,
            'date_from': _this.options.date_from,
            'date_to': _this.options.date_to
          }),
          success: function (response) {
            var data = response.toJSON();

            console.log(data);

            chart_intencao_de_compra_por_fabricante('chart_intencao_de_compra_por_fabricante', data['intencao_de_compra_por_fabricante']);
            chart_presenca_por_fabricante('chart_presenca_por_fabricante', data['presenca_por_fabricante']);
            chart_tentativas_de_bloqueio_por_fabricante('chart_tentativas_de_bloqueio_por_fabricante', data['tentativas_de_bloqueio_por_fabricante']);
            chart_tempo_de_prateleira_por_dispositivo('chart_tempo_de_prateleira_por_dispositivo', data['tempo_de_prateleira_por_dispositivo']);
            chart_intencao_de_compra_por_dispositivo('chart_intencao_de_compra_por_dispositivo', data['intencao_de_compra_por_dispositivo']);
            chart_nivel_de_sinal_do_wifi_por_dispositivo('chart_nivel_de_sinal_do_wifi_por_dispositivo', data['nivel_de_sinal_do_wifi_por_dispositivo']);
          },
          error: function (response) {
            _this.store_data_is_unavailable();
          }
        });
      },
      error: function (response) {
        _this.store_data_is_unavailable();
      }
    });
  },
  datepicker: function(date_min, date_min_current, date_max, date_max_current) {
    var _this = this;

    var _date_from = _.has(_this.options, 'date_from') ? date_min_current : date_max;

    var store_datetimepicker_date_range_from = this.$el.find('#mobilecheck_store_datetimepicker_date_range_from');

    if (store_datetimepicker_date_range_from.length > 0) {
      store_datetimepicker_date_range_from.text(_date_from.format('DD/MM/YYYY'));
    }

    var store_datetimepicker_date_from = _this.$el.find('#mobilecheck_store_datetimepicker_date_from');

    if (store_datetimepicker_date_from.length > 0) {
      store_datetimepicker_date_from.datetimepicker({
        defaultDate: _date_from,
        format: 'LL',
        ignoreReadonly: true,
        inline: true,
        locale: 'pt-br',
        maxDate: date_max,
        minDate: date_min,
        sideBySide: true,
        useCurrent: true,
      });

      store_datetimepicker_date_from.on("change.datetimepicker", function (evt) {
        var store_datetimepicker_date_range_from = _this.$el.find('#mobilecheck_store_datetimepicker_date_range_from');

        if (store_datetimepicker_date_range_from.length > 0) {
          store_datetimepicker_date_range_from.text(evt.date.format('DD/MM/YYYY'));
        }

        var pid = _this.$el.find('#mobilecheck_store_selector').val();
        var date_from = evt.date.format('DD_MM_YYYY');
        var date_to = _this.$el.find('#mobilecheck_store_datetimepicker_date_to').datetimepicker('date').format('DD_MM_YYYY');

        // var store_datetimepicker_date_to = _this.$el.find('#mobilecheck_store_datetimepicker_date_to');

        // if (store_datetimepicker_date_to.length > 0) {
        //    store_datetimepicker_date_to.datetimepicker('minDate', evt.date);
        // }

        window.location.href = '/' + 'store' + '/' + pid + '/' + date_from + '/' + date_to;
      });
    }

    var _date_to = _.has(_this.options, 'date_to') ? date_max_current : date_max;

    var store_datetimepicker_date_range_to = _this.$el.find('#mobilecheck_store_datetimepicker_date_range_to');

    if (store_datetimepicker_date_range_to.length > 0) {
      store_datetimepicker_date_range_to.text(_date_to.format('DD/MM/YYYY'));
    }

    var store_datetimepicker_date_to = this.$el.find('#mobilecheck_store_datetimepicker_date_to');

    if (store_datetimepicker_date_to.length > 0) {
      store_datetimepicker_date_to.datetimepicker({
        defaultDate: _date_to,
        format: 'LL',
        ignoreReadonly: true,
        inline: true,
        locale: 'pt-br',
        maxDate: date_max,
        minDate: date_min,
        sideBySide: true,
        useCurrent: true,
      });

      store_datetimepicker_date_to.on("change.datetimepicker", function (evt) {
        var store_datetimepicker_date_range_to = _this.$el.find('#mobilecheck_store_datetimepicker_date_range_to');

        if (store_datetimepicker_date_range_to.length > 0) {
          store_datetimepicker_date_range_to.text(evt.date.format('DD/MM/YYYY'));
        }

        var pid = _this.$el.find('#mobilecheck_store_selector').val();
        var date_from = _this.$el.find('#mobilecheck_store_datetimepicker_date_from').datetimepicker('date').format('DD_MM_YYYY');
        var date_to = evt.date.format('DD_MM_YYYY');

        // var store_datetimepicker_date_from = _this.$el.find('#mobilecheck_store_datetimepicker_date_from');

        // if (store_datetimepicker_date_from.length > 0) {
        //    store_datetimepicker_date_from.datetimepicker('maxDate', evt.date);
        // }

        window.location.href = '/' + 'store' + '/' + pid + '/' + date_from + '/' + date_to;
      });
    }

    var store_datetimepicker = this.$el.find('#mobilecheck_store_datetimepicker');

    if (store_datetimepicker.length > 0) {
      store_datetimepicker.removeClass('disabled');
    }

  },
  store_selector: function (evt) {
    var pid = evt.target.value;

    window.location.href = '/' + 'store' + '/' + pid;
  },
  store_datetimepicker_filter_today: function (evt) {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    Backbone.history.navigate('/' + 'store' + '/' + pid + '/' + 'today', true);
    console.log('/' + 'store' + '/' + pid + '/' + 'today');
  },
  store_datetimepicker_filter_yesterday: function (evt) {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    Backbone.history.navigate('/' + 'store' + '/' + pid + '/' + 'yesterday', true);
    console.log('/' + 'store' + '/' + pid + '/' + 'yesterday');
  },
  store_datetimepicker_filter_week: function (evt) {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    Backbone.history.navigate('/' + 'store' + '/' + pid + '/' + 'week', true);
    console.log('/' + 'store' + '/' + pid + '/' + 'week');
  },
  store_datetimepicker_filter_month: function (evt) {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    Backbone.history.navigate('/' + 'store' + '/' + pid + '/' + 'month', true);
    console.log('/' + 'store' + '/' + pid + '/' + 'month');
  },
  store_datetimepicker_filter_quarter: function (evt) {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    Backbone.history.navigate('/' + 'store' + '/' + pid + '/' + 'quarter', true);
    console.log('/' + 'store' + '/' + pid + '/' + 'quarter');
  },
  store_datetimepicker_filter_year: function (evt) {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    Backbone.history.navigate('/' + 'store' + '/' + pid + '/' + 'year', true);
    console.log('/' + 'store' + '/' + pid + '/' + 'year');
  },
  store_data_is_unavailable: function () {
    var tpl = $(MobileCheckTemplate).filter('#mobilecheck_data_value_unavailable_tpl');

    if (tpl.length > 0) {
      var t = _.template(tpl.html())({});

      var intencao_de_compra_por_fabricante = this.$el.find('#chart_intencao_de_compra_por_fabricante');

      if (intencao_de_compra_por_fabricante.length > 0) {
        intencao_de_compra_por_fabricante.html(t);
      }

      var presenca_por_fabricante = this.$el.find('#chart_presenca_por_fabricante');

      if (presenca_por_fabricante.length > 0) {
        presenca_por_fabricante.html(t);
      }

      var tentativas_de_bloqueio_por_fabricante = this.$el.find('#chart_tentativas_de_bloqueio_por_fabricante');

      if (tentativas_de_bloqueio_por_fabricante.length > 0) {
        tentativas_de_bloqueio_por_fabricante.html(t);
      }

      var tempo_de_prateleira_por_dispositivo = this.$el.find('#chart_tempo_de_prateleira_por_dispositivo');

      if (tempo_de_prateleira_por_dispositivo.length > 0) {
        tempo_de_prateleira_por_dispositivo.html(t);
      }

      var intencao_de_compra_por_dispositivo = this.$el.find('#chart_intencao_de_compra_por_dispositivo');

      if (intencao_de_compra_por_dispositivo.length > 0) {
        intencao_de_compra_por_dispositivo.html(t);
      }

      var nivel_de_sinal_do_wifi_por_dispositivo = this.$el.find('#chart_nivel_de_sinal_do_wifi_por_dispositivo');

      if (nivel_de_sinal_do_wifi_por_dispositivo.length > 0) {
        nivel_de_sinal_do_wifi_por_dispositivo.html(t);
      }
    }
  },
  store_instalacoes_do_app_detalhado: function () {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    new MobileCheckStoreInstalacoesDoAppDetalhadoModel().fetch({
      cache: false,
      contentType: 'application/json',
      type: 'post',
      data: JSON.stringify({
        'pid': pid
      }),
      success: function (response) {
        var dataJSON = response.toJSON();

        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_instalacoes_do_app_detalhado_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html())({'instalacoes_do_app_detalhado': dataJSON['instalacoes_do_app_detalhado']});

          var store_instalacoes_do_app_detalhado_modal = $(t).filter('#mobilecheck_store_instalacoes_do_app_detalhado_modal');

          if (store_instalacoes_do_app_detalhado_modal.length > 0) {
            store_instalacoes_do_app_detalhado_modal.modal({
              backdrop: true,
              focus: true,
              keyboard: false,
              show: true
            });
          }
        }
      },
      error: function (response) {
        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_instalacoes_do_app_detalhado_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html())({'instalacoes_do_app_detalhado': []});

          var store_instalacoes_do_app_detalhado_modal = $(t).filter('#mobilecheck_store_instalacoes_do_app_detalhado_modal');

          if (store_instalacoes_do_app_detalhado_modal.length > 0) {
            store_instalacoes_do_app_detalhado_modal.modal({
              backdrop: true,
              focus: true,
              keyboard: false,
              show: true
            });
          }
        }
      }
    });
  },
  store_mostruarios_vendidos_detalhado: function () {
    var pid = this.$el.find('#mobilecheck_store_selector').val();

    new MobileCheckStoreMostruariosVendidosDetalhadoModel().fetch({
      cache: false,
      contentType: 'application/json',
      type: 'post',
      data: JSON.stringify({
        'pid': pid
      }),
      success: function (response) {
        var dataJSON = response.toJSON();

        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_mostruarios_vendidos_detalhado_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html())({'mostruarios_vendidos_detalhado': dataJSON['mostruarios_vendidos_detalhado']});

          var store_mostruarios_vendidos_detalhados_modal = $(t).filter('#mobilecheck_store_mostruarios_vendidos_detalhados_modal');

          if (store_mostruarios_vendidos_detalhados_modal.length > 0) {
            store_mostruarios_vendidos_detalhados_modal.modal({
              backdrop: true,
              focus: true,
              keyboard: false,
              show: true
            });
          }
        }
      },
      error: function (response) {
        var tpl = $(MobileCheckTemplate).filter('#mobilecheck_store_mostruarios_vendidos_detalhado_tpl');

        if (tpl.length > 0) {
          var t = _.template(tpl.html())({'mostruarios_vendidos_detalhado': []});

          var store_mostruarios_vendidos_detalhados_modal = $(t).filter('#mobilecheck_store_mostruarios_vendidos_detalhados_modal');

          if (store_mostruarios_vendidos_detalhados_modal.length > 0) {
            store_mostruarios_vendidos_detalhados_modal.modal({
              backdrop: true,
              focus: true,
              keyboard: false,
              show: true
            });
          }
        }
      }
    });
  },
});

export default MobileCheckStoreViewerView;
