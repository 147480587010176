'use strict';

import BackboneExtend from './../../util/backbone_extend';

import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsTreemap from 'highcharts/modules/treemap';

HighchartsExporting(Highcharts);
HighchartsTreemap(Highcharts);

Highcharts.setOptions({
  colors: [
    '#2d6cd7',
    '#29ca9b',
    '#ff7474',
    '#5190fb',
    '#4deebf',
    '#ff9898',
    '#76b5ff',
    '#72ffe4',
    '#ffbdbd',
    '#9ad9ff',
    '#96ffff',
    '#ffe1e1',
    '#eab250',
  ],
  lang: {
    downloadJPEG: 'baixar em JPEG',
    downloadPDF: 'baixar em PDF',
    downloadPNG: 'baixar em PNG',
    downloadSVG: 'baixar em SVG',
  },
  plotOptions: {
    area: { animation: false },
    arearange: { animation: false },
    areaspline: { animation: false },
    areasplinerange: { animation: false },
    bar: { animation: false },
    boxplot: { animation: false },
    bubble: { animation: false },
    column: { animation: false },
    columnrange: { animation: false },
    errorbar: { animation: false },
    funnel: { animation: false },
    gauge: { animation: false },
    heatmap: { animation: false },
    line: { animation: false },
    pie: { animation: false },
    polygon: { animation: false },
    pyramid: { animation: false },
    scatter: { animation: false },
    series: { animation: false },
    solidgauge: { animation: false },
    spline: { animation: false },
    treemap: { animation: false },
    waterfall: { animation: false },
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'downloadPNG',
          'downloadPDF',
          'downloadSVG',
        ]
      }
    },
    enabled: true
  },
  credits: {
    enabled: false
  }
});

export function chart_tempo_de_prateleira_por_dispositivo(render_to, data) {
  var height = _.size(data['data']) * 24;

  return Highcharts.chart(render_to, {
    chart: {
      height: parseInt(height >= 384 ? height : 384) + 'px',
      type: 'bar'
    },
    exporting: {
      filename: 'tempo_de_prateleira_por_dispositivo'
    },
    title: {
      text: 'TEMPO DE PRATELEIRA POR DISPOSITIVO (em dias)',
      style: {
        fontSize: '14px'
      }
    },
    subtitle: {
      text: 'DICA: não recomenda-se manter um produto em exposição por mais de 120 dias (~4 meses), evitando avarias e mal funcionamento.',
      style: {
        fontSize: '14px',
        fontWeight: 'bold'
      },
      verticalAlign: 'bottom'
    },
    xAxis: [
      {
        categories: data['categories_dms'],
        title: {
           text: 'FABRICANTE & MODELO DO DISPOSITIVO'
        }
      },
      {
        categories: data['categories_aids'],
        title: {
           text: 'IDENTIFICAÇÃO DO APP'
        },
        linkedTo: 0,
        opposite: true,
      }
    ],
    yAxis: {
      min: 0,
      max: _.max(data['data']),
      title: {
        text: ''
      },
      allowDecimals: false,
      plotBands: [{
        color: {
          linearGradient:  { x1: 0, x2: 1, y1: 0, y2: 0 },
          stops: [
            [0, '#d9ead3ff'],
            [1, _.max(data['data']) > 120 ? '#f4ccccff': '#d9ead3ff'], // 120 days (~4 months)
          ]
        },
        from: 0,
        to: 2 * _.max(data['data']) // force filling
      }],
      plotLines: [{
        color: 'red',
        value: 120, // 120 days (~4 months)
        width: 2,
        zIndex: 1
      }]
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      enabled: false
    },
    series: [{
      animation: false,
      name: 'TEMPO DE PRATELEIRA POR DISPOSITIVO (em dias)',
      data: data['data'],
      color: Highcharts.getOptions().colors[1]
    }]
  }, function(chart) {
    $.each(chart.series[0].data, function(i, data) {
      if (data.y >= 120) { // 120 days (~4 months)
        chart.series[0].data[i].update({
          color: Highcharts.getOptions().colors[2]
        });
      }
    });
  });
}

export function chart_intencao_de_compra_por_fabricante(render_to, data) {
  return Highcharts.chart(render_to, {
    chart: {
      type: 'column'
    },
    exporting: {
      filename: 'intencao_de_compra_por_fabricante'
    },
    title: {
      text: 'INTENÇÃO DE COMPRA POR FABRICANTE',
      style: {
        fontSize: '14px'
      }
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y}'
        }
      }
    },
    series: [{
      animation: false,
      colorByPoint: true,
      data: data['data'],
      name: 'INTENÇÃO DE COMPRA'
    }]
  });
}

export function chart_presenca_por_fabricante(render_to, data) {
  return Highcharts.chart(render_to, {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    exporting: {
      filename: 'presenca_por_fabricante'
    },
    title: {
      text: 'PRESENÇA POR FABRICANTE',
      style: {
        fontSize: '14px'
      }
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
          distance: -50,
          filter: {
            property: 'percentage',
            operator: '>',
            value: 4
          }
        },
        showInLegend: true
      }
    },
    series: [{
      animation: false,
      name: 'PRESENÇA',
      data: data['data']
    }]
  });
}

export function chart_tentativas_de_bloqueio_por_fabricante(render_to, data) {
  return Highcharts.chart(render_to, {
    chart: {
      type: 'bar'
    },
    exporting: {
      filename: 'tentativas_de_bloqueio_por_fabricante'
    },
    title: {
      text: 'TENTATIVAS DE BLOQUEIO POR FABRICANTE',
      style: {
        fontSize: '14px'
      }
    },
    xAxis: {
      categories: data['categories'],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      allowDecimals: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      enabled: false
    },
    series: [{
      animation: false,
      name: 'TENTATIVAS DE BLOQUEIO',
      data: data['data']
    }]
  });
}

export function chart_intencao_de_compra_por_dispositivo(render_to, data) {
  return Highcharts.chart(render_to, {
    chart: {
      type: 'column'
    },
    exporting: {
      filename: 'intencao_de_compra_por_dispositivo'
    },
    title: {
      text: 'INTENÇÃO DE COMPRA POR DISPOSITIVO',
      style: {
        fontSize: '14px'
      }
    },
    xAxis: {
      categories: data['categories'],
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: 'INTENÇÃO DE COMPRA'
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [{
      animation: false,
      name: 'INTENÇÃO DE COMPRA',
      data: data['data'],
      dataLabels: [{
        enabled: true,
        inside: false,
        style: {
          style: {
            fontSize: '14px'
          }
        }
      }]
    }]
  });
}

export function chart_intencao_de_compra_por_loja(render_to, data) {
  var height = _.size(data['data']) * 24;

  return Highcharts.chart(render_to, {
    chart: {
      height: parseInt(height >= 384 ? height : 384) + 'px',
      type: 'bar'
    },
    exporting: {
      filename: 'intencao_de_compra_por_loja'
    },
    title: {
      text: 'INTENÇÃO DE COMPRA POR LOJA',
      style: {
        fontSize: '14px'
      }
    },
    xAxis: {
      categories: data['categories'],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      max: _.max(data['data']),
      title: {
        text: ''
      },
      allowDecimals: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      enabled: true
    },
    series: [{
      animation: false,
      name: 'INTENÇÃO DE COMPRA POR LOJA',
      data: data['data']
    }]
  });
}

export function chart_intencao_de_compra_por_modelo_de_dispositivo(render_to, data) {
  var height = _.size(data['data']) * 24;

  return Highcharts.chart(render_to, {
    chart: {
      height: parseInt(height >= 384 ? height : 384) + 'px',
      type: 'bar'
    },
    exporting: {
      filename: 'intencao_de_compra_por_modelo_de_dispositivo'
    },
    title: {
      text: 'INTENÇÃO DE COMPRA POR MODELO',
      style: {
        fontSize: '14px'
      }
    },
    xAxis: {
      categories: data['categories'],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      max: _.max(data['data']),
      title: {
        text: ''
      },
      allowDecimals: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      enabled: true
    },
    series: [{
      animation: false,
      name: 'INTENÇÃO DE COMPRA POR MODELO DE DISPOSITIVO',
      data: data['data'],
      color: '#05a677'
    }]
  });
}

export function chart_aderencia_a_guarda_diaria_por_loja(render_to, data) {
  var height = _.size(data['data']) * 24;

  return Highcharts.chart(render_to, {
    chart: {
      height: parseInt(height >= 384 ? height : 384) + 'px',
      type: 'bar'
    },
    exporting: {
      filename: 'aderencia_a_guarda_diaria_por_loja'
    },
    title: {
      text: 'ADERÊNCIA A GUARDA DIÁRIA',
      style: {
        fontSize: '14px'
      }
    },
    xAxis: {
      categories: data['categories'],
      title: {
        text: null
      },
    },
    yAxis: {
      min: 0,
      max: _.max(data['data']),
      title: {
        text: ''
      },
      allowDecimals: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      enabled: true
    },
    series: [{
      animation: false,
      name: 'DISPOSITIVO(S) NÃO ADERENTE(S) A GUARDA DIÁRIA',
      data: data['data']
    }]
  });
}

export function chart_aderencia_ao_planograma_por_loja(render_to, data) {
  var height = _.size(data['data']) * 24;

  return Highcharts.chart(render_to, {
    chart: {
      height: parseInt(height >= 384 ? height : 384) + 'px',
    },
    exporting: {
      filename: 'aderencia_ao_planograma_por_loja'
    },
    title: {
      text: 'ADERÊNCIA AO PLANOGRAMA',
      style: {
        fontSize: '14px'
      }
    },
    colorAxis: {
      minColor: Highcharts.getOptions().colors[0],
      maxColor: Highcharts.getOptions().colors[1]
    },
    series: [{
      animation: false,
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      data: data['data']
    }]
  });
}

export function chart_versionamento_por_eventos(render_to, data) {
  return Highcharts.chart(render_to, {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'VERSIONAMENTO'
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.4f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.4f} %'
        }
      }
    },
    series: [{
      name: 'VERSÃO',
      colorByPoint: true,
      data: data['data']
    }]
  });
}

export function chart_nivel_de_sinal_do_wifi_por_dispositivo(render_to, data) {
  return Highcharts.chart(render_to, {
    chart: {
      type: 'line'
    },
    title: {
      text: 'NÍVEL DE SINAL / WiFi'
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: data['categories'],
      labels: {
        rotation: -45
      }
    },
    yAxis: {
      title: {
        text: 'RSSI (dBm)'
      },
      reversed: true,
      min: -100,
      max: 0
    },
    legend: {
      enabled: false
    },
    series: [{
      name: 'NÍVEL DE SINAL',
      data: data['data'],
      color: Highcharts.getOptions().colors[1]
    }]
  });
}

export function chart_visual_merchandising_score_por_rede(render_to, data) {
  return Highcharts.chart(render_to, {
    chart: {
      type: 'heatmap',
    },
    title: {
      text: 'VMS POR LOJA'
    },
    subtitle: {
      text: 'VISUAL MERCHANDISING SCORE (VMS)'
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    colorAxis: {
      maxColor: Highcharts.getOptions().colors[0],
      min: 0,
      minColor: '#ffffff'
    },
    series: [{
      name: 'Sales per employee',
      animation: false,
      borderWidth: 1,
      dataLabels: {
        color: '#000000',
        enabled: true
      },
      data: data
      /*
      data: [
        [0, 0, 10],
        [0, 1, 19],
        [0, 2, 8],
        [0, 3, 24],
        [0, 4, 67],
        [1, 0, 92],
        [1, 1, 58],
        [1, 2, 78],
        [1, 3, 117],
        [1, 4, 48],
        [2, 0, 35],
        [2, 1, 15],
        [2, 2, 123],
        [2, 3, 64],
        [2, 4, 52],
        [3, 0, 72],
        [3, 1, 132],
        [3, 2, 114],
        [3, 3, 19],
        [3, 4, 16],
        [4, 0, 38],
        [4, 1, 5],
        [4, 2, 8],
        [4, 3, 117],
        [4, 4, 115],
        [5, 0, 88],
        [5, 1, 32],
        [5, 2, 12],
        [5, 3, 6],
        [5, 4, 120],
        [6, 0, 13],
        [6, 1, 44],
        [6, 2, 88],
        [6, 3, 98],
        [6, 4, 96],
        [7, 0, 31],
        [7, 1, 1],
        [7, 2, 82],
        [7, 3, 32],
        [7, 4, 30],
        [8, 0, 85],
        [8, 1, 97],
        [8, 2, 123],
        [8, 3, 64],
        [8, 4, 84],
        [9, 0, 47],
        [9, 1, 114],
        [9, 2, 31],
        [9, 3, 48],
        [9, 4, 91]
      ]
      */
    }],
  });
}
